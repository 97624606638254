<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    
  >
  
    <template #button-content >
      <feather-icon
      badge-classes="bg-danger"
      class="text-body"
      icon="BellIcon"
      size="21"
      />
      <BSpinner v-if="isLoading" />
      <b-badge v-if="getUnReadCounts>0 && !isLoading"  variant="danger">{{getUnReadCounts}}</b-badge>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
        {{getUnReadCounts > 0? getUnReadCounts : 0}} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    
      <!-- Account Notification -->
      <b-link class="bLink"
      style=""
      > 
      <div v-if="getNotification==''" >
        <br/><br/>
    <center>No New Notification</center>
    <br/><br/>

      </div>
      <div v-else style="overflow-y: auto; height:500px ; width: 250px;">
      <div v-for="notification in getNotification" :key="notification" :class="{unRead: !notification.Read}">
        <b-media style="margin:20px; " >
          <template  #aside >
            <!-- {{notification.Read}} -->
            <b-avatar
              v-if="notification.Topic=='Ready For Payment'"
              size="32"
              src="../../assets/yicimages/icons/credit-card.svg"
            />
            <b-avatar
            v-else="notification.Topic=='Item Arrived'"
              size="32"
              src="../../assets/yicimages/icons/package.svg"
            />
            <b-avatar
            v-else="notification.Topic=='Payment Completed'"
              size="32"
              src="../../assets/yicimages/icons/package.svg"
            />
           
          </template>
          <p class="media-heading">
            <small class="">
              {{ new Date(notification.createdAt).toDateString()  }}
            </small>
            <br/>
            <span class="font-weight-bold" :class="{bolder : !notification.Read}">
              {{ notification.Topic }}
            </span>
          </p>
          <small @click="redirect({itemId: notification.Data.ItemId})"  v-if="notification.Data.ItemId" class="notification-text"> {{ notification.Data.message }}</small>
          <small v-if="notification.Data.PackageNo" class="notification-text">{{ notification.Data.message }}</small>
        </b-media>
        </div>
      </div>
      </b-link>

      <!-- System Notification Toggler -->
     

      <!-- System Notifications -->

    <!-- Cart Footer -->
    
    <li class="dropdown-menu-footer">
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="Read(true)"
      v-if="getUnReadCounts>0"
      block
    >Read all notifications</b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="Read(false)"
      v-else
      block
    >UnRead all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template> 

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapState } from "vuex";
import Vue from 'vue'
import VueProgress from 'vue-progress-path'
Vue.use(VueProgress)


export default {
  data() {
    return {
      // login: false,
      // login: true,
      read: null,
      isLoading: false,
    };
  },
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BSpinner,
    
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState({
      getCurrUserDetails: (state)=>{
       return state.basicprofilemodule
      },
      getNotification: (state)=>{
        return state.notifications.notification
      },
      getUnReadCounts: (state)=>{
        return state.notifications.unRead
      }
    }),
   

  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
    ]
    /* eslint-disable global-require */

    

    

    return {
      notifications,
    }
  },
  methods:{
    redirect(dict){
      // console.log(dict[itemId])
      if (dict.itemId){
        // this.$route.push('/view-item/:dict[itemId]')
        this.$router.push({ name: "view-item", params: { id: dict.itemId } });
      }
    },
    ...mapActions({
      getNotifications: "notifications/getNotification",
      setRead: "notifications/setRead",
      unReadCounts: "notifications/unReadCount"
    }),
    async loading() {
      await this.getNotifications()
      await this.unReadCounts()
      // console.log(this.getNotification)
    },
    async Read(val){
      const notificationIds=[]
      for(let index = 0; index < this.getNotification.length; index++){
        notificationIds.push(this.getNotification[index].id)
      };
      console.log(this.getUnReadCounts)
      // this.read = this.getReadCounts>0? false:true
      // this.read = !this.read;
      if (this.getUnReadCounts==this.getNotification.length){
        this.read = true
      }
      else{
        this.read = false
      }
      
      this.isLoading = true
      await this.setRead({read:val, Ids:notificationIds})
      // console.log(notificationIds,this.read)
      await this.loading()
      await this.unReadCounts()
      
      
      setTimeout(()=>{
        this.isLoading = false
      },1000)
    }
  },
  async mounted(){
    await this.loading()

  },
  
}
</script>

<style>
.unRead{
  padding: 2px;
  border-radius:20px ;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: rgba(240, 231, 231, 0.769);
}
.bolder{
  font-weight: bolder !important;
}
</style>
  
  