var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),_c('div',{staticClass:"signup_root"},[_c('b-row',{},[_c('b-col',{staticClass:"d-lg-flex align-items-center",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center   height-90"},[_c('img',{staticClass:"img__signup",attrs:{"fluid":"","src":require("../../assets/yicimages/register/signuppageimage.png"),"alt":"Register V2"}})])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",staticStyle:{"margin-top":"20px"},attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-text',{staticStyle:{"font-size":"large","color":"black"}},[_c('b',[_vm._v("Personal Info")])]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please provide the following info to get started ")]),_c('ValidationObserver',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"register-username"}},[_c('ValidationProvider',{attrs:{"name":"Firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false : null},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"register-username"}},[_c('ValidationProvider',{attrs:{"name":"Lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false : null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"register-country"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control",attrs:{"name":"country","id":"country"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.country=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticStyle:{"color":"black"},attrs:{"value":"0","label":"Select a country ... ","selected":"selected"}},[_vm._v("Select a country ... ")]),_vm._l((_vm.enabledCountries),function(ctry){return _c('option',{staticStyle:{"color":"black"},attrs:{"label":ctry.countryName},domProps:{"value":ctry.countryCode}},[_vm._v(_vm._s(ctry.countryName))])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone number","label-for":"register-country"}},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":_vm.countryCode,"required":"","state":errors.length > 0 ? false : null},on:{"update":_vm.phoneUpdate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(!_vm.isValidPhoneNumber && _vm.phoneNumber != null)?_c('small',{staticClass:"text-danger"},[_vm._v("Phone Number is not valid")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticStyle:{"z-index":"0"},attrs:{"required":"","id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',{on:{"click":function($event){return _vm.privacyPolicy()}}},[_vm._v("privacy policy & terms")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"bttn1",attrs:{"size":"lg","variant":"no-color","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveDemographics.apply(null, arguments)}}},[_vm._v(" Next ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":"/loginv2"}},[_c('span',[_vm._v(" "),_c('b',[_vm._v("Sign in instead")])])])],1)],1)],1)],1)],1),_c('FooterYic')],1)}
var staticRenderFns = []

export { render, staticRenderFns }