<template>
  <div>
    <div class="xyzz icon-large-screen" @click="onClick">
      <!-- <i class="fa-regular fa-circle-user fa-2xl" @click="onClick('register-demo')"></i> -->
      <feather-icon id="bbadge" :badge="`${shoppingCartItems ? shoppingCartItems.length || 0 : 0}`" class="text-body "
        icon="ShoppingCartIcon" size="26" />
      <!-- {{shoppingCartItems}} -->
    </div>
    <div class="xyzz icon-small-screen" @click="onClick">
      <!-- <i class="fa-regular fa-circle-user fa-2xl" @click="onClick('register-demo')"></i> -->
      <feather-icon id="bbadge" :badge="`${shoppingCartItems ? shoppingCartItems.length || 0 : 0}`" class="text-body "
        icon="ShoppingCartIcon" size="20" />
      <!-- {{shoppingCartItems}} -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {},
  directives: {},
  methods: {
    ...mapActions({
      fetchShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
    }),
    onClick() {
      this.$router.push({ name: "cart" });
    },
    async reloadCart() {
      let userId = null;
      if (this.isAuthenticated)
        userId = this.currUser ? this.currUser.id : null;
      await this.fetchShoppingCartItems({ userId });
    },
  },
  computed: mapState({
    shoppingCartItems(state) {
      return state.storeShoppingCartItems.shoppingCartItems;
    },
    currUser(state) {
      return state.login.currUser;
    },
    isAuthenticated(state) {
      return state.login.isAuthenticated;
    },
  }),
  async mounted() {
    await this.reloadCart();
  },
};
</script>

<style scoped>
.xyzz {
  cursor: pointer;
}

.icon-small-screen {
  display: none;
}



/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 1200px) {
  .icon-small-screen {
    display: unset;
  }

  .icon-large-screen {
    display: none;
  }
}
</style>
<style>
.xyzz>span>span {
  background-color: #e87613;
  ;
}
</style>
