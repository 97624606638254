<template>
  <!-- <div class="root__navbar"> -->
  <div>
    <b-navbar toggleable="lg" variant="light" class="navbar-top">
      <b-navbar-brand href="#" v-if="$mq === 'largeDevices'" @click="onClick('homepage')">
        <div class="logo mr-2" />
      </b-navbar-brand>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
      <b-collapse id="nav-collapse" is-nav v-if="$mq === 'largeDevices'">
        <b-navbar-nav v-for="(item, indx) in navItems" :key="indx" class="nav--item"
          :class="{ 'active': indx === parseInt(activeNavItemId) }">
          <b-nav-item @click="clickHandler(indx, item)" class="nav___item">
            <div v-if="indx === 4 && login" class="dashboard-logo" />
            <span v-if="indx <= 4 || login" class="nav__link"
              :class="{ 'active-text': indx === parseInt(activeNavItemId) }">
              {{ item.name }}
            </span>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="hi">
          <b-nav-item v-if="login" class="vhg">
            <notification-dropdown v-if="this.logginInUser.role != 'SUPERADMIN'" @click="notification" />
          </b-nav-item>
          <b-nav-item v-if="login" class="vhg" @click="logout">
            <img src="../../assets/yicimages/navbar/logoutIcon.png" />
          </b-nav-item>
          <b-nav-item v-if="logginInUser.role!='SUPERADMIN'" class="vhg">
            <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="onClick('store')">
              Store
            </b-button>
          </b-nav-item>
          <b-nav-item class="vhg mr-2" v-if="this.logginInUser.role != 'SUPERADMIN'">
            <Cart />
          </b-nav-item>
          <b-nav-item v-if="!login" class="vhg">
            <i class="fa-regular fa-circle-user fa-2xl" @click="onClick('register-demo')"></i>
            <!-- <i class="fa-light fa-circle-user fa-2xl"></i> -->
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>


      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'" class="logoo" @click="onClick('homepage')">
        <img src="../../assets/yicimages/yiclogo.png" crossorigin="anonymous" />
      </div>
      <div class="store-btn-small-device" v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
        <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onClick('store')">
          Store
        </b-button>
      </div>
      <b-nav-item class="vhg mr-2"
        v-if="(this.logginInUser.role != 'SUPERADMIN') && ($mq === 'smallDevices' || $mq === 'mediumDevices')">
        <Cart />
      </b-nav-item>
      <div>
        <div class="usericon" v-b-toggle.sidebar-right v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
          <i class="fa-regular fa-circle-user fa-2xl"></i>
        </div>
        <b-sidebar id="sidebar-right" bg-variant="white" right backdrop shadow>
          <SidebarRight />
        </b-sidebar>
      </div>
    </b-navbar>
  </div>
  <!-- </div> -->
</template>

<script>
import { BNav, BNavItem, BNavbarNav, BSidebar, VBToggle } from "bootstrap-vue";
import logo from "../../assets/yicimages/navbar/logo.png";
import Cart from "./Cart.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import Ripple from "vue-ripple-directive";
import { BButton } from "bootstrap-vue";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BNavbar, BTooltip, BNavItemDropdown, BDropdownItem,
  BNavbarToggle,
  BCollapse,
  BNavbarBrand
} from 'bootstrap-vue'
import Vue from 'vue'
import VueMq from 'vue-mq'
import SidebarRight from './SidebarRight.vue'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1280,
    largeDevices: Infinity,
  }
})


export default {
  components: {
    BNavbarBrand,
    BNavbar, BTooltip, BNavItemDropdown, BDropdownItem,
    BNavbarToggle,
    BCollapse,
    BButton,
    BNav,
    BNavItem,
    Cart,
    NotificationDropdown,
    BNavbarNav,
    BSidebar,
    SidebarRight
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: ["value"],
  data() {
    return {
      logo: logo,
      activeNavItemId: this.value ? parseInt(this.value) : 0,
      navItems: [],
    };
  },
  computed: {
    ...mapState({

      // ActiveNavItemId: (state) => {
      //   return state.selectedNavbarItem.selectedNavItem;
      // },
      login: (state) => {
        return state.login.isAuthenticated;
      },
      logginInUser: (state) => {
        // console.log("logginInUser =="+JSON.stringify(state));
        return state.login.currUser;
      },
      getNavbarRoute: (state) => {
        return state.routesStore.navbar;
      },
    }),
  },
  methods: {
    ...mapMutations({
      // setActiveNavItemId: "selectedNavbarbarItem/SET_NAV_ITEM",
      setNavbarRoute: "routesStore/SET_NAVBAR",
      setSidebarRoute: "routesStore/SET_SIDEBAR",
    }),
    ...mapActions({
      logoutUser: "login/logoutUser",
    }),
    async logout() {
      await this.logoutUser();
      this.$router.push({ name: "homepage" });
      this.setNavbarRoute("homepage");
    },
    clickHandler(indx, routingItem) {
      this.activeNavItemId = indx;
      console.log(this.activeNavItemId);
      if (this.logginInUser.role != "SUPERADMIN") {
        if (indx == 3) {
          this.$router.push({ name: "dashboardLanding" });
          // this.activeNavItemId = indx;
          this.setNavbarRoute("dashboardLanding");
        }
        else if (indx == 0) {
          this.$router.push({ name: "homepage" });
          // this.activeNavItemId = indx;
          this.setNavbarRoute("homepage");
        }
        // else if (indx == 2) {
        // this.$router.push({ name: "store" });
        // this.activeNavItemId = indx;
        // this.setNavbarRoute("store");
        // } 
        else if (indx == 1) {
          this.$router.push({ name: "plans" });
          // this.activeNavItemId = indx;
          this.setNavbarRoute("plans");
        }
        else if (indx == 2) {
          this.$router.push({ name: "howyicworks" });
          // this.activeNavItemId = indx;
          this.setNavbarRoute("howyicworks");
        }
        else {
          if (routingItem.url) {
            this.$router.push(routingItem.url);
          }
        }
      } else {
        if (routingItem.url) {
          this.$router.push(routingItem.url);
        }
      }
    },
    onClick(name) {
      console.log(name);
      if (name === "signup") this.$router.push({ name: "register-demo" });
      else this.$router.push({ name });
    },
    ...mapActions({
      getNotifications: "notifications/getNotification",
      fetchShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
    }),
    async notification() {
      await this.getNotifications();
    },
  },
  mounted() {
    if (this.logginInUser.role == "SUPERADMIN") {
      // this.navItems = [{ name: "Home", url: "" }];
      this.navItems.push({ name: "My Dashboard", url: "/adminDashboard" });
    } else {
      this.navItems = [
        { name: "Home", url: "" },
        { name: "Plans", url: "" },
        // { name: "", url: "" },
        { name: "How YIC Works", url: "" },
        { name: "My Dashboard", url: "" },
      ];
    }
  },
  watch: {
    getNavbarRoute(newVal, oldVal) {
      if (newVal === "homepage") {
        this.activeNavItemId = 0;
      }
      else if (newVal === 'Plans') {
        this.activeNavItemId = 1;
      }
      // else if (newVal === 'store') {
      //   this.activeNavItemId = 2;
      // }
      else if (newVal === 'howyicworks') {
        console.log("HERE")
        this.activeNavItemId = 2;
        console.log(this.activeNavItemId)
      }
      else if (newVal == "dashboardLanding") {
        this.activeNavItemId = 3;
      }
    },
    activeNavItemId(nw, od) {
      console.log(nw);
    }
  },
};
</script>
<style scoped>
.root__navbar {
  background-color: #f5f5f5;
  height: 94px;
  display: flex;
  align-items: center;
  width: 100%;
  /* gap: 5rem; */
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 3rem;
  height: 100%;
}

.nav--item {
  height: 100%;
  color: #414141;
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 7rem;
  background-image: url("../../assets/yicimages/navbar/logo.png");
  min-width: 224px;
  height: 67px;
}

.nav__link {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #414141;
  /* display: inline-block; */
  max-height: 30px;
}

.dashboard-logo {
  background-image: url("../../assets/yicimages/navbar/DashboardIcon.png");
  width: 37px;
  background-repeat: no-repeat;
  height: 30px;
  display: inline-block;
  margin-right: 0.5rem;
  /* margin-top:1rem */
}

/* .active {
  border-bottom: #e87613 4px solid;
} */

.active-text {
  color: #e87613;
  font-weight: 600;
  font-size: 20px;
  /* margin-top: 0.3rem; */
}

.xy {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.bttns {
  width: 600px;
  margin-top: 6rem;
}

.bttn1 {
  background: #e87613;
  color: #ffffff;
  font-size: 20px;
  width: 185px;
  height: 48px;
  margin-right: 1rem
}

.login_btn {
  background: #4385f5;
  color: #fff;
}

.bttn2 {
  /* margin-right: 1.5rem; */
  background: #4385f5;
  color: #ffffff;
  width: 185px;
  height: 40px;
}

.spacing {
  margin-left: 5rem;
}

.navbar-top {
  padding: 1rem;
}

.logoo {
  display: flex;
  width: 180px;
  height: 50px;
}

.logoo>img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}



/*========================== new css ==========================*/
.hi {
  margin-left: auto;
}

.nav___item {
  margin-right: 2rem;
}

.vhg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-btn-small-device {
  margin-left: auto;
  margin-right: 0.5rem;
}

/*========================= Media Queries (Med devices) =========================*/
@media screen and (max-width: 1200px) {
  .logo {
    width: 1vw;
    height: 1vh;
    margin-left: 2rem;
    background-image: url("../../assets/yicimages/navbar/logo.png");
  }

  .usericon {
    margin-right: 1rem;
  }

  .bttn2 {
    width: fit-content;
  }

  .bttn1 {
    width: fit-content;
    font-size: 14px;
    height: 36px;
    margin-right: 0.2rem;

  }

  .vhg {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .logoo {
    max-width: 130px;
    height: 50px;
  }
}




</style>


<style>
.nav-link {
  display: flex;
  align-items: center;
}
</style>
