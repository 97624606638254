<template>
  <div>
    <Navbar />
    <div class="signup_root">
      <b-row class="">
        <!-- Brand logo-->

        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="6" class="d-lg-flex align-items-center">
          <div class="w-100 d-flex align-items-center justify-content-center   height-90">
            <!-- <b-img fluid src="../../assets/yicimages/register/HumaaansWireframe1.png" alt="Register V2" /> -->
            <img class="img__signup" fluid src="../../assets/yicimages/register/signuppageimage.png" alt="Register V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Register-->
        <b-col style="margin-top:20px" lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-text style="font-size: large; color: black">
              <b>Personal Info</b>
            </b-card-text>
            <b-card-text class="mb-2">
              Please provide the following info to get started
            </b-card-text>

            <!-- form -->
            <ValidationObserver ref="registerForm">
              <b-form class="auth-register-form mt-2">
                <!-- firstname -->
                <b-form-group label="First Name" label-for="register-username">
                  <ValidationProvider #default="{ errors }" name="Firstname" rules="required">
                    <b-form-input id="register-firstname" v-model="firstName" name="register-firstname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <!-- lastname -->
                <b-form-group label="Last Name" label-for="register-username">
                  <ValidationProvider #default="{ errors }" name="Lastname" rules="required">
                    <b-form-input id="register-lastname" v-model="lastName" name="register-lastname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- email -->
                <b-form-group label="Email" label-for="register-email">
                  <ValidationProvider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input id="register-email" v-model="email" name="register-email"
                      :state="errors.length > 0 ? false : null" placeholder="john@example.com" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group label="Country" label-for="register-country">
                  <ValidationProvider #default="{ errors }" name="Country" rules="required">
                    <select name="country" v-model="country" class="form-control" id="country">
                      <option style="color:black;" value="0" label="Select a country ... " selected="selected">Select a
                        country ... </option>
                          <option v-for="ctry in enabledCountries" style="color: black;" :value="ctry.countryCode" :label="ctry.countryName">{{ ctry.countryName }}</option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group label="Phone number" label-for="register-country">
                  <ValidationProvider #default="{ errors }" name="Phone Number" rules="required">
                    <!-- <VuePhoneNumberInput v-model="phoneNumber" :fetch-country="true" @update="phoneUpdate"
                      required :state="errors.length> 0 ? false : null" /> -->
                    <VuePhoneNumberInput v-model="phoneNumber" @update="phoneUpdate" :default-country-code="countryCode"
                      required :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="!isValidPhoneNumber && phoneNumber != null" class="text-danger">Phone Number is not
                      valid</small>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-checkbox required id="register-privacy-policy" v-model="status" name="checkbox-1"
                      style="z-index: 0">
                      I agree to
                      <b-link @click="privacyPolicy()">privacy policy & terms</b-link>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>

                  </ValidationProvider>
                </b-form-group>

                <b-button size="lg" variant="no-color" type="submit" @click.prevent="saveDemographics" class="bttn1">
                  Next
                </b-button>
              </b-form>
            </ValidationObserver>

            <p class="text-center mt-2">
              <span>Already have an account?</span>
              <b-link to="/loginv2">
                <span >&nbsp;<b>Sign in instead</b></span>
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Register-->
      </b-row>
    </div>
    <FooterYic />

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Navbar from '../navbar/Navbar.vue'
import FooterYic from '../homepage/footer.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    FooterYic,

    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Navbar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      country: "",
      status: false,
      phone: "",
      phoneInputColor: '',
      isValidPhoneNumber: true,
      countryCode: 'US'
    };
  },
  computed: {
    ...mapState({
      enabledCountries: state => {
        return state.countriesSelection.enabledCountries
      }
    })
  },
  methods: {
    ...mapMutations({ setDemographics: "register/SET_DEMOGRAPHICS" }),
    ...mapActions({
      getEnabledCountries: 'countriesSelection/getEnabledCountries',
    }),
    phoneUpdate(event) {
      this.phone = event.formattedNumber;
      if (this.phoneNumber != '')
        this.isValidPhoneNumber = event.isValid;
    },
    saveDemographics() {
      const comp = this;
      this.$refs.registerForm.validate().then((success) => {
        if (
          success &&
          this.country !== "" &&
          // this.phoneNumber.replaceAll(" ", "").length == 10 &&
          this.status !== false &&
          this.isValidPhoneNumber == true
        ) {
          comp.setDemographics({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email.toLowerCase(),
            phone: this.phone,
            country: this.country,
          });
          this.$router.push("/register-pass");
        }
      });
    },
    privacyPolicy() {
      // this.$router.push({name : 'privacyPolicy'})
      let route = this.$router.resolve({ path: "privacy-policy" });
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, "_blank");
    },
  },
  async mounted(){
    await this.getEnabledCountries();
  },
  watch: {
    country(nVal, oVal) {
      this.countryCode = nVal
    }
  }
};
/* eslint-disable global-require */
</script>
<style>
.prime-blue-background {
  background-color: #4385f5;
}

select option {
  margin: 40px;
  background-color: white;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
</style>
<style scoped>
.bttn1 {
  width: auto;
  max-height: 40px;
  border: 1px solid #4385f5;
  background: #4385f5;
  color: #fff;
  margin-right: 2rem;
  font-size: 16px;
  display: flex;
}

.signup_root {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin:3rem 0;
}

.img__signup {
  padding-top: 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  /* height: 50%; */
}


@media screen and (max-width: 600px) {
  .img__signup {
    width: 90%;
  }

}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
