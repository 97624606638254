<template>
	<!-- Footer -->
	<div class="root">
		<!-- Section: Social media -->


		<section v-if="$mq === 'largeDevices' || $mq === 'mediumDevices'" class="">
			<div class="text-center text-md-start mt-5">
				<!-- flex row -->
				<div class="flex">
					<!-- flex column -->
					<div class="flex1 ">
						<!-- Content -->
						<h6 class="text-uppercase fw-bold mb-4" style="color:white">
							My Account
						</h6>
						<p>
							<a @click="() => { this.$router.push({ name: 'account-settings' }) }" class="text-reset">Account
								Settings</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'cart' }) }" class="text-reset">Cart</a>
						</p>

						<p>
							<a @click="() => { this.$router.push({ name: 'dashboardLanding' }) }"
								class="text-reset">Dashboard</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'productsInWarehouse' }) }" class="text-reset">My Orders</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'prohibitedItems' }) }"
								class="text-reset">Prohibited
								Items</a>
						</p>
					</div>
					<!-- flex column -->
					<!-- flex column -->
					<div class="flex2">
						<!-- Links -->
						<h6 class="text-uppercase fw-bold mb-4" style="color:white">Quick links</h6>
						<p>
							<a @click="() => { this.$router.push({ name: 'store' }) }" class="text-reset">Store</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'plans' }) }" class="text-reset">Plans</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'howyicworks' }) }" class="text-reset">How YIC
								Works</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'aboutUs' }) }" class="text-reset">About Us</a>
						</p>
						<!-- <p>
							<a @click="() => { this.$router.push({ name: 'faq' }) }" class="text-reset">Faq</a>
						</p> -->

					</div>
					<!-- flex column -->
					<!-- flex column -->
					<div class="flex3">
						<!-- Links -->
						<h6 class="text-uppercase mb-4" style="color:white">Useful links</h6>
						<p>
							<a @click="() => { this.$router.push({ name: 'shipping-cost-calculator' }) }"
								class="text-reset">Shipping Cost Calculator</a>
						</p>
						<p>
							<a @click="() => { this.$router.push({ name: 'privacyPolicy' }) }" class="text-reset">Privacy
								And
								Policy</a>
						</p>

						<p>
							<a @click="() => { this.$router.push({ name: 'TermsAndConditions' }) }" class="text-reset">Terms
								and
								Conditions</a>
						</p>

						<p>
							<a @click="() => { this.$router.push({ name: 'homepage' }) }" class="text-reset">Home</a>
						</p>
						<!-- <p>
							<a @click="() => { this.$router.push({ name: 'homepage' }) }" class="text-reset">Help and
								Support</a>
						</p> -->
					</div>
					<!-- flex column -->
					<!-- flex column -->
					<div class="flex4">
						<!-- Links -->
						<h6 class="text-uppercase fw-bold mb-4" style="color:white">Contact Us</h6>
						<p>
							<i class="fas fa-home me-3"></i>
							PRI10 E-SOLUTIONS PRIVATE LTD
							<br />
							FLAT C, NO.22,
							2ND MAIN RD,
							<br />
							NORTH JAGANNATHAN NAGAR,
							<br />
							CHENNAI-600049
						</p>
						<p>
							<i class="fas fa-envelope me-3"></i>
							support@yourindiancart.com
						</p>
						<p>
							<b>
								Office Hours :
							</b>
							Mon - Fri 10AM-6PM IST
						</p>
						<p>
							<i class="fas fa-phone me-3"></i>
							+91 9025984338
						</p>
						<p>
							<!-- <i class="fab fa-whatsapp " style="font-size:22px;color:green"></i> -->
							<img src="../../assets/yicimages/whtsppIcon.png" class="w_icon" />

							+91 6385133144
						</p>
					</div>
					<!-- flex column -->
				</div>
				<!-- flex row -->
			</div>
		</section>
		<section v-if="$mq === 'smallDevices'" class="links">


			<div class="linksMain">
				<div class="quickLinks">
					<!-- Links -->
					<h6 class="text-uppercase fw-bold mb-3" style="color:white">Quick links</h6>
					<p>
						<a @click="() => { this.$router.push({ name: 'store' }) }" class="text-reset">Store</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'plans' }) }" class="text-reset">Plans</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'howyicworks' }) }" class="text-reset">How YIC
							Works</a>
					</p>
					<!-- <p>
						<a @click="() => { this.$router.push({ name: 'faq' }) }" class="text-reset">Faq</a>
					</p> -->
					<p>
						<a @click="() => { this.$router.push({ name: 'shipping-cost-calculator' }) }"
							class="text-reset">Shipping Cost Calculator</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'privacyPolicy' }) }" class="text-reset">Privacy And
							Policy</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'TermsAndConditions' }) }" class="text-reset">Terms
							and
							Conditions</a>
					</p>


					<!-- <p>
						<a @click="() => { this.$router.push({ name: 'homepage' }) }" class="text-reset">Help and
							Support</a>
					</p> -->
				</div>
				<div class="accounts ">
					<!-- Content -->
					<h6 class="text-uppercase fw-bold mb-3" style="color:white">
						My Account
					</h6>
					<p>
						<a @click="() => { this.$router.push({ name: 'account-settings' }) }" class="text-reset">Account
							Settings</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'cart' }) }" class="text-reset">Cart</a>
					</p>

					<p>
						<a @click="() => { this.$router.push({ name: 'dashboardLanding' }) }"
							class="text-reset">Dashboard</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'productsInWarehouse' }) }" class="text-reset">My Orders</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'prohibitedItems' }) }" class="text-reset">Prohibited
							Items</a>
					</p>
					<p>
						<a @click="() => { this.$router.push({ name: 'homepage' }) }" class="text-reset">Home</a>
					</p>
				</div>

			</div>
			<div class=" text-center contact">
				<h6 class="text-uppercase fw-bold mb-3" style="color:white">Contact Us</h6>
				<p>
					<i class="fas fa-home me-3"></i> PRI10 E-SOLUTIONS PRIVATE LTD

					FLAT C, NO.22,
					2ND MAIN RD,
					NORTH JAGANNATHAN NAGAR,
					CHENNAI-600049
				</p>
				<p>
					<i class="fas fa-envelope me-3"></i>
					support@yourindiancart.com
				</p>
				<p>

					<b>
						Office Hours :
					</b>
					Mon - Fri 10AM-6PM IST
				</p>

				<p>
					<i icon="phone" class="me-3" />
					<i class="fas fa-phone me-3"></i>
					+91 9025984338
				</p>
				<p>
					<!-- <i class="fab fa-whatsapp " style="font-size:22px;color:green"></i> -->
					<img src="../../assets/yicimages/whtsppIcon.png" class="w_icon"/>
					+91 6385133144
				</p>
			</div>



		</section>
		<!-- Section: Links  -->
		<!-- Copyright -->
		<div class="text-center p-2" style="background-color: rgba(0, 0, 0, 0.05)">
			© {{ currentYear }} Copyright:
			<a class="text-reset fw-bold" href="http://staging.yourindiancart.com/">yourindiancart.com</a>
		</div>
		<!-- Copyright -->
	</div>
	<!-- Footer -->
</template>
<script>
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
	breakpoints: {
		smallDevices: 600,
		mediumDevices: 1200,
		largeDevices: Infinity,
	}
})

export default {
	components: {

	},
	data() {
		return {
			currentYear: null
		}
	},
	mounted() {
		const date = new Date();
		this.currentYear = date.getFullYear();
	}

};
</script>
<style scoped>
.root {
	background-color: #414141;
	color: white !important;
	font-weight: bold;
	/* margin-top: 100px; */
	padding: 20px
}

.socialSection {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding-bottom: 40px;
}

.flex {
	display: flex;
	justify-content: space-evenly;
}

.icons a {
	margin: 40px;
}

.w_icon {
	width: 25px;
	height: 25px;
}

@media screen and (max-width: 600px) {
	.socialSection {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		flex-wrap: wrap;
		padding-bottom: 30px;
		margin-top: 20px;



	}

	.socialTitle {
		margin-left: auto;
		margin-right: auto;
	}

	.socialIcons {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	.socialIcons a {
		margin: 20px;

	}

	.links {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	.linksMain {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.contact {
		margin-top: 20px;
	}


}
</style>
  